

































































































import Vue from "@/translate";
import AppMain from "@/components/common/AppMain.vue";
import AppFooter from "@/components/common/AppFooter.vue";
import { ROOT_ACTIONS } from "@/store/actions";
import AdminMixin from "@/mixins/admin";
import ColorsMixin from "@/mixins/color-scheme";
import AdminClient from "@/client/admin";
import LanguageIcon from "@/components/LanguageIcon.vue";
import UserMenu from "@/layouts/UserMenu.vue";

export default Vue.extend(AdminMixin)
  .extend(ColorsMixin)
  .extend({
    name: "AdminLayout",
    components: {
      UserMenu,
      LanguageIcon,
      AppMain,
      AppFooter,
    },
    data() {
      return {
        auto: false,
        mini: false,
      };
    },
    async created() {
      const root = await AdminClient.RootQuery();
      await this.$store.dispatch(ROOT_ACTIONS, root);
    },
  });
