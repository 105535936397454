






import Vue from "@/translate";
import AdminLayout from "@/layouts/AdminLayout.vue";

export default Vue.extend({
  name: "Admin",
  components: {
    AdminLayout,
  },
});
